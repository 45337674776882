import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const SyncAlt = ({ size, fill = colorsV2.text[1], ...rest }: IIcon) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M2.82127 10.8957L4.57814 12.6524C4.70564 12.7799 4.76939 12.9291 4.76939 13.0999C4.76939 13.2709 4.70564 13.4201 4.57814 13.5475C4.44777 13.678 4.29633 13.7425 4.12383 13.741C3.95145 13.7395 3.80302 13.675 3.67852 13.5475L0.835455 10.7044C0.766955 10.6359 0.717205 10.5653 0.686205 10.4924C0.65533 10.4194 0.639893 10.3394 0.639893 10.2524C0.639893 10.1685 0.65533 10.0901 0.686205 10.0171C0.717205 9.94407 0.766955 9.87332 0.835455 9.80482L3.69727 6.94301C3.82477 6.81563 3.97396 6.75195 4.14483 6.75195C4.31583 6.75195 4.46502 6.81563 4.59239 6.94301C4.71989 7.07051 4.78439 7.2197 4.78589 7.39057C4.78739 7.56157 4.72439 7.71076 4.59689 7.83813L2.82127 9.61376H14.2233C14.4068 9.61376 14.5599 9.67432 14.6825 9.79545C14.8053 9.9167 14.8666 10.069 14.8666 10.2524C14.8666 10.4389 14.8053 10.5927 14.6825 10.7138C14.5599 10.8351 14.4068 10.8957 14.2233 10.8957H2.82127ZM13.1831 4.40201H1.77671C1.59321 4.40201 1.44089 4.34145 1.31977 4.22032C1.19852 4.09907 1.13789 3.94526 1.13789 3.75888C1.13789 3.57538 1.19852 3.42307 1.31977 3.30194C1.44089 3.18069 1.59321 3.12007 1.77671 3.12007H13.1831L11.4076 1.34463C11.2771 1.21713 11.2119 1.06795 11.2119 0.89707C11.2119 0.72607 11.2771 0.575382 11.4076 0.445007C11.535 0.317507 11.685 0.254508 11.8575 0.256008C12.0298 0.257508 12.1783 0.320507 12.3028 0.445007L15.1691 3.31132C15.2346 3.37982 15.2828 3.45051 15.3138 3.52338C15.3447 3.59638 15.3601 3.67488 15.3601 3.75888C15.3601 3.84576 15.3447 3.9257 15.3138 3.9987C15.2828 4.0717 15.2346 4.14095 15.1691 4.20645L12.3215 7.05401C12.194 7.18138 12.0448 7.24507 11.874 7.24507C11.703 7.24507 11.5538 7.18138 11.4264 7.05401C11.2989 6.92651 11.2344 6.77732 11.2329 6.60645C11.2314 6.43545 11.2959 6.28626 11.4264 6.15888L13.1831 4.40201Z" />
  </svg>
);
