export const balancetTransactionSingularMapping: { [key: string]: string } = {
  refund: "Refund",
  disputed_payment: "Disputed payment",
  dispute_fee: "Dispute fee",
  dispute_won: "Dispute won",
  dispute_fee_reversal: "Dispute fee reversal",
  referral_bonus: "Referral bonus",
  vat: "VAT",
  other: "Adjustment",
  premium_fee: "Premium fee",
  starter_fee: "Starter fee",
  enterprise_fee: "Enterprise fee",
  payout: "Payout",
  early_settlement_fee: "Early settlement fee",
  invoice_repayment: "Invoice repayment",
  payout_canceled: "Payout canceled",
  api_integration_support: "Integration support",
  account_top_up: "Account top up",
  card_top_up: "Card top up",
  card_fee: "Card fee",
  card_top_up_canceled: "Card top up canceled",
  advance_card_funding: "Advance card funding",
  advance_card_repayment: "Advance card repayment",
  advance_card_repayment_fee: "Advance card repayment fee",
  ultra_fee: "Ultra fee",
  cash_withdrawal_fee: "Cash withdrawal fee",
  expedited_verification_fee: "Expedited verification fee",
  vat_credit_adjustment: "VAT credit adjustment",
  transfer: "Transfer",
  cashback: "Cashback",
  duplicate_settlement_reversal: "Duplicate settlement reversal",
  international_payout: "International payout",
  international_payout_fee: "International payout fee",
  card_balance_transfer: "Card balance transfer",
  card_top_up_reversal: "Card top up reversal",
  negative_balance_payment: "Negative balance payment",
  payout_fee: "Payout fee",
  dispute_cancelled: "Dispute cancelled",
  dispute_resolved: "Dispute resolved",
  hold_reversal_fee: "Hold reversal fee",
  dispute_reversal: "Dispute reversal",
  rolling_reserve: "Rolling reserve",
  rolling_reserve_released: "Rolling reserve released",
  account_top_up_reversal: "Account top up reversal",
  fee_reversal: "Fee reversal",
  nymcard_prefund_top_up: "Nymcard prefund top up",
  credit_card_repayment: "Credit card repayment",
  credit_card_funding: "Credit card funding",
};

export const balancetTransactionPluralMapping: { [key: string]: string } = {
  refund: "Refunds",
  disputed_payment: "Disputed payments",
  dispute_fee: "Dispute fee",
  dispute_won: "Disputes won",
  dispute_fee_reversal: "Dispute fee reversal",
  referral_bonus: "Referral bonus",
  vat: "VAT",
  other: "Adjustments",
  premium_fee: "Premium fee",
  starter_fee: "Starter fee",
  enterprise_fee: "Enterprise fee",
  payout: "Payouts",
  early_settlement_fee: "Early settlement fee",
  invoice_repayment: "Invoice repayment",
  payout_canceled: "Payouts canceled",
  api_integration_support: "Integration support",
  account_top_up: "Account top up",
  card_top_up: "Card top up",
  card_fee: "Card fee",
  card_top_up_canceled: "Card top up canceled",
  advance_card_funding: "Advance card funding",
  advance_card_repayment: "Advance card repayment",
  advance_card_repayment_fee: "Advance card repayment fee",
  ultra_fee: "Ultra fee",
  cash_withdrawal_fee: "Cash withdrawal fee",
  expedited_verification_fee: "Expedited verification fee",
  vat_credit_adjustment: "VAT credit adjustment",
  transfer: "Transfers",
  cashback: "Cashback",
  duplicate_settlement_reversal: "Duplicate settlement reversal",
  international_payout: "International payout",
  international_payout_fee: "International payout fee",
  card_balance_transfer: "Card balance transfer",
  card_top_up_reversal: "Card top up reversal",
  negative_balance_payment: "Negative balance payment",
  payout_fee: "Payout fee",
  dispute_cancelled: "Dispute cancelled",
  dispute_resolved: "Dispute resolved",
  hold_reversal_fee: "Hold reversal fee",
  dispute_reversal: "Dispute reversal",
  rolling_reserve: "Rolling reserves",
  rolling_reserve_released: "Rolling reserves released",
  account_top_up_reversal: "Account top up reversal",
  fee_reversal: "Fee reversal",
  nymcard_prefund_top_up: "Nymcard prefund top up",
  credit_card_repayment: "Credit card repayment",
  credit_card_funding: "Credit card funding",
};

export const allowKindsPointerEvent: Array<string> = [
  "refund",
  "disputed_payment",
  "dispute_fee",
  "dispute_won",
  "dispute_fee_reversal",
  "dispute_cancelled",
  "dispute_resolved",
  "dispute_reversal",
];
