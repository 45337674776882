import { colorsV2 } from "constants/colors";
import type { IIcon } from "types/Icon";

export const MamoFavIcon = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: IIcon) => {
  return (
    <svg
      {...rest}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mamo-business-favicon-32">
        <g clipPath="url(#clip0_836_39678)">
          <path id="Vector" d="M24 0H0V24H24V0Z" fill="#F5F5F5" />
          <path id="Vector_2" d="M24 0H0V24H24V0Z" fill="#2DB37C" />
          <g id="Clip path group">
            <mask
              id="mask0_836_39678"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <g id="clip0_0_1">
                <path id="Vector_3" d="M24 0H0V24H24V0Z" fill="white" />
              </g>
            </mask>
            <g mask="url(#mask0_836_39678)">
              <g id="Group">
                <path id="Vector_4" d="M24 0H0V24H24V0Z" fill="#3333FF" />
                <g
                  id="Group_2"
                  opacity="0.8"
                  filter="url(#filter0_f_836_39678)"
                >
                  <path
                    id="Vector_5"
                    d="M-7.97257 12.6044C-8.89906 9.4227 -10.0553 3.33926 -7.26851 4.45913C-3.78496 5.85899 -6.59823 -11.9115 -2.1791 -1.8623C-2.17832 -1.86054 -2.17755 -1.85877 -2.17678 -1.857C-1.83501 -1.28442 -1.13823 -1.01083e-05 0.106545 2.40137C7.40825 16.4878 2.91451 -4.27939 6.72206 -0.405816C9.44417 3.77053 14.4542 13.911 12.7174 21.0624C10.9807 28.2137 7.02077 29.0622 5.25791 28.5925C6.13061 31.8533 4.50812 27.8482 0.813162 26.5201C-3.80563 24.8601 -5.10936 34.8824 -8.28136 23.524C-10.819 14.4372 -9.13283 12.4581 -7.97257 12.6044Z"
                    fill="#FF267D"
                  />
                </g>
                <g
                  id="Group_3"
                  opacity="0.7"
                  filter="url(#filter1_f_836_39678)"
                >
                  <path
                    id="Vector_6"
                    d="M21.9874 22.6982C21.0121 25.0635 24.4789 25.5297 26.2472 25.4934C26.0056 26.5636 26.7811 28.704 31.8156 28.704C38.1088 28.704 38.7349 25.1669 40.2506 21.1945C41.4632 18.0165 38.1638 17.1495 36.3626 17.1132C36.8568 15.5532 36.9886 11.5408 33.5619 7.97106C30.1352 4.40127 24.2044 6.48366 21.6673 7.97106C19.1302 10.577 14.9994 12.7536 16.4492 16.0326C17.1167 17.542 13.4129 18.5296 14.5819 19.9083C15.6165 21.1285 23.2065 19.7415 21.9874 22.6982Z"
                    fill="#FF267D"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group 1">
            <path
              id="Vector_7"
              d="M11.9944 6C10.347 6 8.81149 6.6552 7.67005 7.84512C6.52765 9.03648 5.93629 10.6032 6.00541 12.2573C6.13501 15.3677 8.67181 17.8891 11.7803 17.9966C11.8523 17.999 11.9243 18.0005 11.9963 18.0005C13.5635 18.0005 15.0376 17.4062 16.168 16.3142C17.3493 15.1733 18.0001 13.6411 18.0001 12.0005C18.0001 8.69232 15.3059 6.00048 11.9944 6.00048V6ZM16.612 12.24C16.4939 14.5738 14.5739 16.4942 12.2401 16.6118C10.9345 16.6776 9.65581 16.187 8.73421 15.2654C7.81261 14.3438 7.32205 13.0661 7.38781 11.7595C7.50589 9.42576 9.42589 7.50528 11.7597 7.38768C13.0667 7.32144 14.344 7.81248 15.2656 8.73408C16.1872 9.65568 16.6777 10.9334 16.612 12.24Z"
              fill="white"
            />
            <path
              id="Vector_8"
              d="M12.0001 15.5851C13.936 15.5851 15.5161 14.0328 15.5613 12.1075H8.43903C8.48415 14.0328 10.0643 15.5851 12.0001 15.5851Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_836_39678"
          x="-22.2719"
          y="-17.6226"
          width="48.0136"
          height="60.1303"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="6.33333"
            result="effect1_foregroundBlur_836_39678"
          />
        </filter>
        <filter
          id="filter1_f_836_39678"
          x="5.01866"
          y="-3.33333"
          width="44.8267"
          height="41.3706"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4.66667"
            result="effect1_foregroundBlur_836_39678"
          />
        </filter>
        <clipPath id="clip0_836_39678">
          <rect width="24" height="24" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
