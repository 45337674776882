import { useContext } from "react";
import { cn } from "utils/classNames";

import { FadeIn } from "components/AnimatedElements/FadeIn";
import { Divider } from "components/Divider";

import { FullPageLayoutContext } from "../context/FullPageLayout.context";
import type { ContentProps } from "../types/FullPageLayout.types";

const COMMON_CLASS_NAMES =
  "flex flex-col md:flex-row flex-1 overflow-y-auto px-16 md:px-0 pt-40 md:pt-24 lg:pt-40";

export const ContentLayout = ({
  hasFadeIn,
  position,
  children,
  className,
}: ContentProps) => {
  const { isMobile } = useContext(FullPageLayoutContext);

  if (!hasFadeIn) {
    return (
      <>
        <section
          data-testid="content-area"
          className={cn(COMMON_CLASS_NAMES, {
            "md:justify-center":
              position === undefined || position === "center",
            "md:justify-start md:px-80": position === "left",
            "md:justify-end md:px-80": position === "right",
          })}
        >
          <section className={cn("w-full max-w-628", className)}>
            {children}
          </section>
        </section>
        {!isMobile && <Divider />}
      </>
    );
  }

  return (
    <>
      <FadeIn
        custom={1}
        className={cn(COMMON_CLASS_NAMES, {
          "md:justify-center": position === undefined || position === "center",
          "md:justify-start md:px-80": position === "left",
          "md:justify-end md:px-80": position === "right",
        })}
      >
        <section className="flex w-full max-w-628 flex-col">{children}</section>
      </FadeIn>
      {!isMobile && <Divider />}
    </>
  );
};
