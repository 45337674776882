import { createContext } from "react";

import type { TPermissions } from "types/Permissions";

const generateInitPermissionObject = (): TPermissions => {
  return {
    hasReadAccess: false,
    hasWriteAccess: false,
  };
};

const initState = {
  cardFunds: generateInitPermissionObject(),
  cards: generateInitPermissionObject(),
  createButtonPermissions: generateInitPermissionObject(),
  developer: generateInitPermissionObject(),
  disputes: generateInitPermissionObject(),
  expenses: generateInitPermissionObject(),
  invoices: generateInitPermissionObject(),
  myCards: generateInitPermissionObject(),
  myExpenses: generateInitPermissionObject(),
  overview: generateInitPermissionObject(),
  paymentLinks: generateInitPermissionObject(),
  payments: generateInitPermissionObject(),
  paymentsAccordionPermissions: generateInitPermissionObject(),
  paymentsConditionsArray: generateInitPermissionObject(),
  payouts: generateInitPermissionObject(),
  pos: generateInitPermissionObject(),
  settings: generateInitPermissionObject(),
  settlements: generateInitPermissionObject(),
  spendLimits: generateInitPermissionObject(),
  spendManagementAccordionPermissions: generateInitPermissionObject(),
  spendManagementConditionsArray: generateInitPermissionObject(),
  subscriptions: generateInitPermissionObject(),
  transactionsAccordionPermissions: generateInitPermissionObject(),
  transactionsConditionsArray: generateInitPermissionObject(),
  wallet: generateInitPermissionObject(),

  //
  spendLimitsPermissions: generateInitPermissionObject(),
};

export type PermissionsContextType = Record<string, TPermissions>;

export const PermissionsContext = createContext<PermissionsContextType | any>(
  initState,
);
