import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useCreateAccount } from "features/Access/SignUp/6_PlanToUse/hooks/useCreateAccount";
import { useRedirectToSignUp } from "features/Access/SignUp/shared/useRedirectToSignUp";
import { FullPageLayout } from "features/Cards/features/CreateCard/core/components/FullPageLayout/FullPageLayout";

import { Button } from "design_system/Button";

import { useScreenDimension } from "hooks/useScreenDimension";
import { updateData } from "reducers/redux/Access/SignUp/signUpSlice";
import { useAppSelector } from "store";

import routes from "constants/routes";
import { Loader } from "design_system/Loader";
import { cn } from "utils/classNames";

export const ReviewTerms = () => {
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const { isMobile } = useScreenDimension();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useAppSelector((state) => state.signUp);
  const { email } = data;

  const { redirectToSignUp } = useRedirectToSignUp();
  const { createAccount } = useCreateAccount();

  // Loading state for the submit button
  const { isLoading } = createAccount;

  const handleCreateAccount = () => {
    dispatch(updateData({ data: { agreedTermsAndPrivacy: true } }));
    createAccount.mutateAsync();
  };

  const handleClose = () => {
    navigate(routes.ONBOARDING.PLAN_TO_USE(), {
      replace: true,
    });
  };

  useEffect(() => {
    redirectToSignUp(email);
  }, []);

  return (
    <FullPageLayout className="h-svh" isLoading={false} isMobile={isMobile}>
      <FullPageLayout.Header
        isMobile={isMobile}
        onClose={handleClose}
        title="Review terms"
      />
      <FullPageLayout.Content
        hasFadeIn={false}
        position="left"
        className="relative h-full w-full max-w-full"
      >
        {isLoadingIframe && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Loader />
          </div>
        )}
        <iframe
          src="https://www.mamopay.com/legal/terms-business-internal"
          title="Terms and conditions"
          width="100%"
          height="100%"
          className={cn({
            "opacity-10": isLoadingIframe,
          })}
          onLoad={() => {
            setIsLoadingIframe(false);
          }}
        />
      </FullPageLayout.Content>
      <FullPageLayout.Footer className="px-10">
        <Button
          style={{ minWidth: "48px" }}
          classes="w-full md:w-auto"
          variant="filled"
          loading={isLoading}
          onClick={handleCreateAccount}
          label="Agree and continue"
          size="lg"
        />
      </FullPageLayout.Footer>
    </FullPageLayout>
  );
};
