import { pureCn } from "utils/classNames";

interface IProps {
  color?: string;
  size?: string;
  isIcon?: boolean;
  iconPosition?: string;
  iconFont?: string;
  onClick?: any;
  isDisabled?: boolean;
  label?: string;
  classes?: string;
  img?: string;
}

const TextButton = ({
  color,
  size,
  isIcon,
  iconPosition,
  iconFont,
  onClick,
  isDisabled,
  label,
  classes,
}: IProps) => {
  if (isIcon) {
    return (
      <button
        className={pureCn(
          "text-button text-button--solid",
          `text-button--${color}`,
          `text-button--${size}`,
          "text-button--icon",
          classes,
        )}
        onClick={onClick}
        disabled={isDisabled}
      >
        <i className="material-icons-round">{iconFont}</i>
      </button>
    );
  } else if (iconPosition === "right") {
    return (
      <button
        className={pureCn(
          "text-button text-button--solid",
          `text-button--${color}`,
          `text-button--${size}`,
          `text-button--icon-${iconPosition}`,
          classes,
        )}
        onClick={onClick}
        disabled={isDisabled}
      >
        {label}
        <i className="material-icons-round">{iconFont}</i>
      </button>
    );
  } else if (iconPosition === "left") {
    return (
      <button
        className={pureCn(
          "text-button text-button--solid",
          `text-button--${color}`,
          `text-button--${size}`,
          `text-button--icon-${iconPosition}`,
          classes,
        )}
        onClick={onClick}
        disabled={isDisabled}
      >
        <i className="material-icons-round">{iconFont}</i>
        {label}
      </button>
    );
  } else {
    return (
      <button
        className={pureCn(
          "text-button text-button--solid",
          `text-button--${color}`,
          `text-button--${size}`,
          classes,
        )}
        onClick={onClick}
        disabled={isDisabled}
      >
        {label}
      </button>
    );
  }
};

export default TextButton;
