import { cn } from "utils/classNames";

interface IProps {
  show?: any;
  close?: any;
  children?: any;
  fullOnMobile?: boolean;
  classes?: string;
  testId?: string;
}

const Modal = ({
  show,
  close,
  children,
  fullOnMobile,
  classes,
  testId,
}: IProps) => {
  return (
    <>
      {show && (
        <div
          data-testid={testId}
          className={`modal ${fullOnMobile && "modal--full"}`}
        >
          <div
            className={cn(
              "modal__box",
              {
                "modal__box--full": fullOnMobile,
              },
              classes,
            )}
          >
            <i
              className="modal__box__close material-icons-round"
              onClick={close}
            >
              close
            </i>
            <div className="modal__box__content">{children}</div>
          </div>
          <div className="modal__overlay" onClick={close}></div>
        </div>
      )}
    </>
  );
};

export default Modal;
export { Modal, type IProps as ModalProps };
