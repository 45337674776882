import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "design_system/Button";
import { ChoiceCard } from "design_system/ChoiceCard";
import { InfoCard } from "design_system/InfoCard";
import { snack } from "design_system/Snackbar";

import { useAppSelector } from "store";
import { useRedirectToSignUp } from "../shared/useRedirectToSignUp";
import { useCreateAccount } from "./hooks/useCreateAccount";

import {
  togglePlanToUse,
  updateData,
} from "reducers/redux/Access/SignUp/signUpSlice";

import CardsFeatureGradientIcon from "assets/icons/cards-feature-gradient.png";
import PaymentsFeatureGradientIcon from "assets/icons/payment-links-feature-gradient.png";
import PayoutsFeatureGradientIcon from "assets/icons/payouts-feature-gradient.png";
import WebsiteCheckoutFeatureGradientIcon from "assets/icons/website-checkout-feature-gradient.png";
import routes from "constants/routes";

export const PlanToUse = () => {
  const { data } = useAppSelector((state) => state.signUp);
  const { email, onboardingRole, planToUse, complianceType } = data;
  const { redirectToSignUp } = useRedirectToSignUp();
  const { createAccount } = useCreateAccount();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hasNoTradeLicense =
    onboardingRole === "freelancer" || onboardingRole === "personal_use";

  const handleTogglePlanToUse = (value: PlanToUse) => {
    dispatch(togglePlanToUse(value));
  };

  const goToReviewTerms = () => {
    navigate(routes.ONBOARDING.REVIEW_TERMS(), {
      replace: true,
    });
  };

  const handleUpdateComplianceToBusiness = () => {
    dispatch(updateData({ data: { onboardingRole: null } }));
    dispatch(updateData({ data: { complianceType: "business" } }));
    snack({
      title: "Great! Corporate cards have been added to your products.",
      leadingElement: "icon",
      variant: "success",
    });
  };

  const choiceCards = [
    <ChoiceCard
      checkboxProps={{
        value: "cards",
        onChange: () => handleTogglePlanToUse("cards"),
      }}
      htmlFor="plan-to-use-cards"
      label="Corporate cards"
      supportingText="Issue unlimited virtual and physical Visa cards, set spend limits, and manage company expenses."
      leadingElement="image"
      imgUrl={CardsFeatureGradientIcon}
      isSelected={planToUse.includes("cards")}
      disabled={hasNoTradeLicense}
    >
      {hasNoTradeLicense && (
        <InfoCard
          showIcon
          description={
            <>
              Corporate cards are currently only available for businesses with a
              registered trade license.{" "}
              <Link
                to="#"
                className="text-primary-1"
                onClick={handleUpdateComplianceToBusiness}
              >
                I have a trade license
              </Link>
            </>
          }
        />
      )}
    </ChoiceCard>,
    <ChoiceCard
      checkboxProps={{
        value: "payments",
        onChange: () => handleTogglePlanToUse("payments"),
      }}
      htmlFor="plan-to-use-payments"
      label="Payment links"
      supportingText="Receive payments in any currency. Use payment links, invoices, subscriptions."
      leadingElement="image"
      imgUrl={PaymentsFeatureGradientIcon}
      isSelected={planToUse.includes("payments")}
    />,
    <ChoiceCard
      checkboxProps={{
        value: "website_checkout",
        onChange: () => handleTogglePlanToUse("website_checkout"),
      }}
      htmlFor="plan-to-use-website-checkout"
      label="Website checkout"
      supportingText="Embed Mamo's payment form directly into your website to receive payments or subscriptions in any currency."
      leadingElement="image"
      imgUrl={WebsiteCheckoutFeatureGradientIcon}
      isSelected={planToUse.includes("website_checkout")}
    />,
    <ChoiceCard
      checkboxProps={{
        value: "payouts",
        onChange: () => handleTogglePlanToUse("payouts"),
      }}
      htmlFor="plan-to-use-payouts"
      label="Payouts"
      supportingText="Disburse funds to your suppliers or partners to pay them."
      leadingElement="image"
      imgUrl={PayoutsFeatureGradientIcon}
      isSelected={planToUse.includes("payouts")}
    />,
  ];

  const orderedChoiceCards =
    complianceType === "individual"
      ? [choiceCards[1], choiceCards[2], choiceCards[3], choiceCards[0]]
      : choiceCards;

  useEffect(() => {
    redirectToSignUp(email);
  }, []);

  return (
    <div className="flex flex-col gap-32">
      <div className="max-w-screen-md space-y-8 text-center">
        <h1 className="header-large md:display-medium">
          What do you plan to use Mamo for?
        </h1>
        <p className="body-medium md:body-large mx-auto w-3/4 text-center">
          Select at least one that fits your needs. You can add more products at
          any time.
        </p>
      </div>

      <div
        style={{ maxWidth: "410px" }}
        className="mx-auto flex flex-col gap-16"
      >
        {orderedChoiceCards.map((card, index) => (
          <React.Fragment key={index}>{card}</React.Fragment>
        ))}
      </div>

      <div
        style={{ maxWidth: "410px" }}
        className="mx-auto flex flex-col gap-24"
      >
        <span className="body-medium lg:body-large text-left">
          By continuing, you agree to Mamo’s{" "}
          <a
            className="text-primary-1 hover:underline"
            href="https://www.mamopay.com/legal/terms-business"
            target="_blank"
            rel="noreferrer"
          >
            terms and conditions
          </a>{" "}
          and{" "}
          <a
            className="text-primary-1 hover:underline"
            href="https://www.mamopay.com/legal/privacy"
            target="_blank"
            rel="noreferrer"
          >
            privacy policy
          </a>
          .
        </span>
        <Button
          variant="filled"
          size="lg"
          label="Create account"
          classes="w-full"
          onClick={goToReviewTerms}
          disabled={!planToUse.length}
          loading={createAccount.isLoading}
        />
      </div>
    </div>
  );
};
