import { createPortal } from "react-dom";

import { Loader } from "design_system/Loader";
import { ContentLayout } from "./components/ContentLayout";
import { FooterLayout } from "./components/FooterLayout";
import { HeaderLayout } from "./components/HeaderLayout";

import { cn } from "utils/classNames";
import { FullPageLayoutProvider } from "./context/FullPageLayout.context";
import type { FullPageLayoutProps } from "./types/FullPageLayout.types";

export const FullPageLayout = ({
  hideDividers,
  isLoading,
  isMobile,
  children,
  className,
}: FullPageLayoutProps) => {
  return createPortal(
    <FullPageLayoutProvider
      hideDividers={hideDividers ?? false}
      isMobile={isMobile}
    >
      <main
        className={cn(
          "absolute top-0 flex h-screen w-screen flex-col bg-surface-1",
          className,
        )}
        style={{
          zIndex: 1003,
        }}
      >
        {isLoading ? <Loader variant="fullPage" /> : children}
      </main>
    </FullPageLayoutProvider>,
    document.body,
  );
};

FullPageLayout.Header = HeaderLayout;
FullPageLayout.Content = ContentLayout;
FullPageLayout.Footer = FooterLayout;
